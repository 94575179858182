import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import React from 'react'
import { Alert, Badge, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import AlertDynamic from '../../../components/AlertDynamic'
import { useGetCountriesQuery } from '../../../components/api'
import Layout from '../../../components/Layout'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import { unauthNavTo } from '../../../components/navigation'
import NotificationToaster from '../../../components/NotificationToaster'
import { getLabelFromArray } from '../../../components/static'
import { useCreateGroup, useGetCompaniesQuery, useUpdateGroup } from '../../../utils/api'
const EditGroup = (props) => {
  const { location: { state = {} } = {} } = props
  const { groupData = {} } = state === null ? {} : state
  //   console.log(groupData, 'group')
  const [selectedRowIndexes, setSelectedRowIndexes] = React.useState([])
  const [rightSelectedRowIndexes, setRightSelectedRowIndexes] = React.useState([])
  const [rightSideRows, setRightSidedRows] = React.useState([])
  const [groupName, setGroupName] = React.useState(groupData.isDuplicate ? groupData.name + ' Duplicate' : groupData.name || '')
  const [isShowToaster, setIsShowToaster] = React.useState(false)
  const [leftRowsPerpage, setLeftRowsPerpage] = React.useState(25)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [sortOrder, setSortOrder] = React.useState({
    name: 'nickName',
    direction: 'asc'
  })
  const [leftSortOrder, setLeftSortOrder] = React.useState({
    name: 'nickName',
    direction: 'asc'
  })

  const companiesQuery = useGetCompaniesQuery()
  const updateGroupMutation = useUpdateGroup()
  const createGroupMutation = useCreateGroup()
  const countriesQuery = useGetCountriesQuery()
  const transferRight = () => {
    const selectedRows = companiesQuery.data.filter((c) => c.status === 'active').filter((c) => rightSideRows.includes(c) === false).filter((_, index) =>
      selectedRowIndexes.includes(index)
    )
    const union = selectedRows.concat(rightSideRows.filter(v => !selectedRows.includes(v))) // [1,2,3,4,5]
    setRightSidedRows(union)
    setSelectedRowIndexes([])
  }
  const transferLeft = () => {
    const unSelectedRows = rightSideRows.filter((_, index) =>
      !rightSelectedRowIndexes.includes(index)
    )

    setRightSidedRows(unSelectedRows)
    setRightSelectedRowIndexes([])
  }
  React.useEffect(() => {
    if (groupData.groupMembers && companiesQuery.data) {
      const groupArrary = []
      companiesQuery.data.forEach(element => {
        groupData.groupMembers.forEach(groupElement => {
          if (element.id === groupElement.id) {
            groupArrary.push(element)
          }
        })
      })

      setRightSidedRows(groupArrary)
    }
  }, [groupData.groupMembers, companiesQuery.data])
  const handleSubmit = () => {
    if (groupData.isDuplicate) {
      createGroupMutation
        .mutateAsync({ group: { name: groupName, groupMembers: rightSideRows } }).then(() => {
          unauthNavTo.groupManagement({ status: 'success', date: new Date() })
        }).catch((err) => {
          console.log(err)
        })
    } else {
      updateGroupMutation
        .mutateAsync({ group: { id: groupData._id, name: groupName, groupMembers: rightSideRows } }).then(() => {
          unauthNavTo.groupManagement({ status: 'success', message: groupData.isDuplicate ? '' : 'Change successful!  ', date: new Date() })
        }).catch((err) => {
          console.log(err)
        })
    }
  }
  return (
    <Layout>
      <Layout.Title>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="px-4 py-0">{groupData.name}</h5>
        </div>
      </Layout.Title>

      <Layout.CardGrey className="my-account-card card card-grey">
        <NotificationToaster
          showToast={isShowToaster}
          setShowToasFn={setIsShowToaster}
          isSuccess={true}
          message={'Group Created!'}
        />
        <div className="d-flex align-items-center justify-content-between">
          <h4 className=" px-2 pt-5 px-md-5 font-weight-bold">Group Name</h4>{' '}
        </div>
        <Row>
          <Col>
            {false && (
              <div className="d-flex justify-content-center p-5">
                <Spinner />
              </div>
            )}
            {false && (
              <Alert variant="danger" className="mb-0 mt-3 text-center"></Alert>
            )}
            {true && (
              <div className="px-2 px-md-5 d-flex flex-column flex-grow-1 my-3">
                <p className="mb-3 font-group-weight-400">
                  Edit the group name below.
                </p>
                {/* form */}
                <Form className="d-flex flex-column flex-grow-1 justify-content-between">
                  <Form.Group className="w-75">
                    <Form.Label>Group Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
            )}
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <div className="pl-3 pl-md-5 d-flex flex-column flex-grow-1 my-3">
              {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}

              {companiesQuery.data && rightSideRows && (
                <div>
                  <div className="d-flex justify-content-between">
                    <h4 className="pt-1  font-weight-bold">
                      Group Participants
                    </h4>
                  </div>
                  <p className="mb-3 font-group-weight-400">
                    Add participants to this group.
                  </p>
                  <p className="mb-0 font-group-weight-600">All Participants</p>
                  <Badge
                    pill
                    variant={'outline-info '}
                    style={{
                    //   position: 'relative',
                    //   top: '31px',
                      marginTop: '10px',
                      zIndex: 20,
                      width: '122px',
                      height: '32px'
                    }}
                    className="text-capitalize"
                  >
                    <span className="font-group-weight-600 ml-2">
                      {' '}
                      {selectedRowIndexes.length} selected
                    </span>
                  </Badge>
                  <MUIDataTable
                  className="custmized-transerable-table"
                    columns={[
                      {
                        label: 'Company',
                        name: 'nickName',
                        options: {
                          customBodyRender: (value, tableMeta) => {
                            console.log(tableMeta.rowData)
                            if (value) {
                              return value
                            } else {
                              return tableMeta.rowData[3]
                            }
                          }
                        }
                      },
                      {
                        label: 'Country',
                        name: 'country',
                        options: {
                          customBodyRender: (value) =>
                            countriesQuery.isSuccess &&
                            typeof value === 'string' &&
                            getLabelFromArray(
                              countriesQuery.data,
                              value.toLowerCase()
                            )
                        }
                      },

                      {
                        lable: ' ',
                        name: 'id',
                        options: {
                          display: false
                        }
                      },
                      {
                        label: 'Registered Company Name',
                        name: 'companyName',
                        options: {
                          display: false
                        }
                      }
                    ]}
                    data={companiesQuery.data
                      ?.filter((c) => c.status === 'active')
                      .filter((c) => rightSideRows.includes(c) === false)}
                    options={{
                      pagination: true,
                      jumpToPage: false,
                      print: false,
                      search: true,
                      download: true,
                      rowsPerPageOptions: [10, 15, 25, 100],
                      sort: true,
                      sortOrder: leftSortOrder,
                      onColumnSortChange: (changedColumn, direction) => {
                        setLeftSortOrder({ name: changedColumn, direction: direction })
                      },
                      filter: true,
                      filterType: 'multiselect',
                      viewColumns: true,
                      elevation: 0,
                      rowsPerPage: leftRowsPerpage,
                      onChangeRowsPerPage: (numberOfRows) => {
                        setLeftRowsPerpage(numberOfRows)
                      },
                      rowsSelected: selectedRowIndexes,

                      onRowSelectionChange: (
                        currentRowsSelected,
                        allRowsSelected,
                        rowsSelected
                      ) => {
                        if (
                          selectedRowIndexes.join(',') !==
                          rowsSelected.join(',')
                        ) {
                          setSelectedRowIndexes(rowsSelected)
                        }
                      },
                      selectToolbarPlacement: 'none'

                    }}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col xs={1} className={'py-auto px-auto m-auto '}>
            <Button
              sx={{ my: 0.5 }}
              variant="outline-primary mb-4 mx-auto d-block"
              className='custmized-floadting-button-right'
              // style={{ width: '55px', height: '40px', position: 'fixed', top: '50%', left: '53.5%' }}
              disabled={selectedRowIndexes.length === 0}
              onClick={() => {
                transferRight()
              }}
              aria-label="move selected right"
            >
              <ChevronRight/>
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outline-primary mx-auto d-block"
              disabled={rightSelectedRowIndexes.length === 0}
               className='custmized-floadting-button-left'
              // style={{ width: '55px', height: '40px', position: 'fixed', top: '60%', left: '53.5%' }}
              onClick={() => {
                transferLeft()
              }}
              aria-label="move selected left"
            >
            <ChevronLeft/>
            </Button>
          </Col>
          <Col>
            {true && (
              <div className="pr-4  pr-md-5 d-flex flex-column flex-grow-1 mt-4 mb-3">
                {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}

                <p
                  className=" mb-0 font-group-weight-600"
                  style={{ marginTop: '70px' }}
                >
                  Participants Assigned to Group
                </p>

                <Badge
                  pill
                  variant={'outline-info '}
                  style={{
                    //   position: 'relative',
                    //   top: '31px',
                    marginTop: '10px',
                    zIndex: 20,
                    width: '122px',
                    height: '32px'
                  }}
                  className="text-capitalize"
                >
                  <span className="font-group-weight-600 ml-2">
                    {' '}
                    {rightSelectedRowIndexes.length} selected
                  </span>
                </Badge>
                <MUIDataTable
                  className="custmized-transerable-table"
                  columns={[
                    {
                      label: 'Company',
                      name: 'nickName',

                      options: {
                        customBodyRender: (value, tableMeta) => {
                          console.log(tableMeta.rowData)
                          if (value) {
                            return value
                          } else {
                            return tableMeta.rowData[3]
                          }
                        }
                      }
                    },
                    {
                      label: 'Country',
                      name: 'country',
                      options: {
                        customBodyRender: (value) =>
                          countriesQuery.isSuccess &&
                          typeof value === 'string' &&
                          getLabelFromArray(
                            countriesQuery.data,
                            value.toLowerCase()
                          )
                      }
                    },

                    {
                      lable: ' ',
                      name: 'id',
                      options: {
                        display: false
                      }
                    },
                    {
                      label: 'Registered Company Name',
                      name: 'companyName',
                      options: {
                        display: false
                      }
                    }
                  ]}
                  data={rightSideRows}
                  options={{
                    pagination: true,
                    jumpToPage: false,
                    print: false,
                    search: true,
                    download: true,
                    rowsPerPageOptions: [10, 15, 25, 100],
                    filterType: 'multiselect',
                    sort: true,
                    sortOrder: sortOrder,
                    onColumnSortChange: (changedColumn, direction) => {
                      setSortOrder({ name: changedColumn, direction: direction })
                      console.log('changedColumn', changedColumn, direction)
                    },
                    filter: true,
                    viewColumns: true,
                    elevation: 0,
                    rowsPerPage: rowsPerPage,
                    onChangeRowsPerPage: (numberOfRows) => {
                      setRowsPerPage(numberOfRows)
                    },
                    selectToolbarPlacement: 'none',
                    rowsSelected: rightSelectedRowIndexes,
                    onRowSelectionChange: (
                      currentRowsSelected,
                      allRowsSelected,
                      rowsSelected
                    ) => {
                      if (
                        rightSelectedRowIndexes.join(',') !==
                        rowsSelected.join(',')
                      ) {
                        setRightSelectedRowIndexes(rowsSelected)
                      }
                    }

                  }}
                />

                {/* form */}
              </div>
            )}
          </Col>
        </Row>

        {updateGroupMutation.isError && (
            <div className=" my-3 px-3 text-center">
            <AlertDynamic variant="danger" msg={'' + updateGroupMutation.error.message}>

        </AlertDynamic>
        </div>)}

          {createGroupMutation.isError && (
            <div className=" my-3 px-3 text-center">
            <AlertDynamic variant="danger" msg={'' + createGroupMutation.error.message}>

        </AlertDynamic>
        </div>)}
        <Row className="mt-0 mb-4 d-flex justify-content-between">
          <Button
            variant="outline-primary mx-6"
            onClick={() => unauthNavTo.groupManagement()}
          >
            Cancel
          </Button>
          <div>
            <Button
              variant="primary mr-6"
              disabled={groupName === '' || rightSideRows.length === 0}
              onClick={() => {
                handleSubmit()
              }}
            >
              {groupData.isDuplicate ? 'Create' : 'Save Changes'}
            </Button>
          </div>
        </Row>

      </Layout.CardGrey>
    </Layout>
  )
}

export default EditGroup
