import React from 'react'
import {
  Toast
} from 'react-bootstrap'
import styled from 'styled-components'
import errorSvg from '../images/error.svg'
import successSvg from '../images/positive.svg'

const FloatTop = styled.div`
position:fixed;
left:0;right:0;top:80px;
width:100%;
z-index: 9999;
.toast {
margin-left:auto !important;
margin-right:auto !important;
}
`

const NotificationToaster = (props) => {
  const { showToast, setShowToasFn, isSuccess = true, message = '' } = props
  return (
    <FloatTop>
      <Toast
        onClose={() => setShowToasFn(false)}
        show={showToast}
        delay={4000}
        autohide
        animation
        className={
          showToast
            ? (!isSuccess
                ? 'toast-status-fail align-items-center d-flex '
                : 'toast-status-success align-items-center d-flex')
            : ''
        }
      >
        <Toast.Header className="d-flex justify-content-center pl-4">
          <img
            src={!isSuccess ? errorSvg : successSvg}
            className="rounded me-2 mr-3"
            alt=""
            height="20"
          />{' '}
          <strong>
            {message}
          </strong>
        </Toast.Header>
      </Toast>
    </FloatTop>
  )
}

export default NotificationToaster
